// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
    about_us_banner: dynamic(() => import("./AboutUsBanner")),
    about_us_text_section: dynamic(() => import("./AboutUsTextSection")),
    ambassador_perks_and_benefits: dynamic(
        () => import("./AmbassadorPerksAndBenefits")
    ),
    ambassador_program_form: dynamic(() => import("./AmbassadorProgramForm")),
    application_submitted: dynamic(() => import("./ApplicationSubmitted")),
    banner: dynamic(() => import("./Banner")),
    banner_styling_session: dynamic(() => import("./StylingSessionBanner")),
    become_an_ambassador_banner: dynamic(
        () => import("./BecomeAnAmbassadorBanner")
    ),
    blog_section: dynamic(() => import("./BlogSection")),
    catalog: dynamic(() => import("./Catalog")),
    faq: dynamic(() => import("./Faq")),
    fully_style_inclusive_section: dynamic(
        () => import("./FullyStyleInclusiveSection")
    ),
    gift_card_form: dynamic(() => import("./GiftCardForm")),
    how_it_works: dynamic(() => import("./HowItWorks")),
    how_to_wear_that: dynamic(() => import("./HowToWearThat")),
    how_we_work: dynamic(() => import("./HowWeWork")),
    image_carousel: dynamic(() => import("./ImageCarousel")),
    journals_section: dynamic(() => import("./JournalsSection")),
    landing_popup: dynamic(() => import("./LandingPopup")),
    latest_drops: dynamic(() => import("./LatestDrops")),
    news: dynamic(() => import("./News")),
    next_steps: dynamic(() => import("./NextSteps")),
    our_values: dynamic(() => import("./OurValues")),
    shop_the_look_carrousel: dynamic(() => import("./ShopTheLookCarrousel")),
    style_advice: dynamic(() => import("./StyleAdvice")),
    styling_session: dynamic(() => import("./StylingSession")),
    styling_session_form: dynamic(() => import("./StylingSessionForm")),
    stylist_data_section: dynamic(() => import("./StylistDataSection")),
    stylist_profile_advice: dynamic(() => import("./StylistProfileAdvice")),
    stylists: dynamic(() => import("./Stylists")),
    testimonials: dynamic(() => import("./Testimonials")),
    timeline: dynamic(() => import("./Timeline")),
    who_we_are1: dynamic(() => import("./WhoWeAre1")),
};
